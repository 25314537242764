import styled, { css } from 'styled-components';
import { Colors } from '../../../styles/colors';
import { media } from '../../../styles/media';
import { FeaturesGridProps } from './FeaturesGrid.types';

export const Container = styled.div<FeaturesGridProps>`
  margin: 0 auto;
  max-width: 1600px;
`;

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  ${media.from_tablet`
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  `}
  ${media.from_laptop`
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  `}
  ${media.from_large_laptop`
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
  `}
`;

export const FeatureWrapper = styled.div`
  padding: 8px;
  text-align: center;
  display: flex;
  gap: 8px;
  flex-direction: row;
  max-width: 400px;
  margin-inline: auto;
  flex-wrap: wrap;
  ${media.from_tablet`
    flex-wrap: nowrap;
    max-width: 100%;
  `}
`;

export const FeatureIcon = styled.div`
  display: flex;
  width: 100px;
  height: 100px;
  flex: 1 0 120px;
  justify-content: center;
`;

export const FeatureBody = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
`;

export const FeatureTitle = styled.div`
  color: ${Colors.PrimaryDarkest};
  font-size: 1.5rem;
`;

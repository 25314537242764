import { Container, FeatureBody, FeatureIcon, FeatureTitle, FeatureWrapper, GridWrapper } from './FeaturesGrid.styles';

import aiIcon from '../../../assets/icons/ai.svg';
import editIcon from '../../../assets/icons/edit.svg';
import jobOfferIcon from '../../../assets/icons/job-offer.svg';
import presentationIcon from '../../../assets/icons/presentation.svg';
import contactBookIcon from '../../../assets/icons/contact-book.svg';
import taskManagerIcon from '../../../assets/icons/task-manager.svg';
import googleMapViewIcon from '../../../assets/icons/google-map-view.svg';
import aiCoverLetterIcon from '../../../assets/icons/ai-cover-letter.svg';
import organise1Icon from '../../../assets/icons/organise1.svg';
import organise3Icon from '../../../assets/icons/organise3.svg';
import collaborate1Icon from '../../../assets/icons/collaborate1.svg';
import collaborate2Icon from '../../../assets/icons/collaborate2.svg';
import { Copy } from '../../atoms/Typography';
import { Tooltip } from '../../atoms/Tooltip';
import { IconButton } from '../../atoms/Icons/Icon';
import { CrownIcon } from '../../atoms/Icons';
import { CopyColoredSpan } from '../../atoms/Typography/Copy';

export const FeaturesGrid = () => {
  return (
    <Container>
      <GridWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Organise'} src={presentationIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Jobs Tracker Board</FeatureTitle>
            <Copy styleLevel={2}>
              Track your job applications and maintain a well-organized job search, all in one place.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Collaborate'} src={collaborate1Icon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Collaborate</FeatureTitle>
            <Copy styleLevel={2}>
              Collaborate directly with your recruiter within the JobsBoard<CopyColoredSpan>.io</CopyColoredSpan>{' '}
              platform embrace efficiency.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Organise'} src={taskManagerIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Tasks Manager</FeatureTitle>
            <Copy styleLevel={2}>
              Effortlessly navigate every stage of your journey with our tasks manager, paving the way to secure your
              dream job. <strong>Premium users can also enjoy the AI-powered tasks manager.</strong>
            </Copy>
          </FeatureBody>
        </FeatureWrapper>

        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Resume'} src={aiIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>
              <Tooltip
                title={
                  <IconButton>
                    <CrownIcon size={0.6} />
                  </IconButton>
                }
              >
                <Copy styleLevel={2}>Premium feature only</Copy>
              </Tooltip>
              AI Powered Resume
            </FeatureTitle>
            <Copy styleLevel={2}>
              Elevate your chances with a standout resume tailored swiftly for each application, opening doors to a
              plethora of career opportunities, all crafted with the help of our AI assistant.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Organise'} src={aiCoverLetterIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>
              <Tooltip
                title={
                  <IconButton>
                    <CrownIcon size={0.6} />
                  </IconButton>
                }
              >
                <Copy styleLevel={2}>Premium feature only</Copy>
              </Tooltip>
              Matching Cover Letters
            </FeatureTitle>
            <Copy styleLevel={2}>
              Let our AI craft personalized cover letters and important emails tailored to the jobs you're applying for
              and aligned with your search preferences.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Organise'} src={jobOfferIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>
              <Tooltip
                title={
                  <IconButton>
                    <CrownIcon size={0.6} />
                  </IconButton>
                }
              >
                <Copy styleLevel={2}>Premium feature only</Copy>
              </Tooltip>
              Import Job Applications
            </FeatureTitle>
            <Copy styleLevel={2}>
              Let our AI importer seamlessly bring in job applications from URLs and automatically fill in all the
              details for you on your Jobs Board.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>

        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Contact'} src={contactBookIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Manage contacts</FeatureTitle>
            <Copy styleLevel={2}>
              Keep contact details for everyone you engage with during your search, from recruiters to interviewers and
              all those in between.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Organise'} src={collaborate2Icon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Manage Documents</FeatureTitle>
            <Copy styleLevel={2}>
              Upload your resumes, cover letters, and any other job search documents you need, then effortlessly share
              them with your recruiter or showcase them to the world.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Take Notes Icon'} src={editIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Take Notes</FeatureTitle>
            <Copy styleLevel={2}>
              Capture every detail of your job search journey and stay organized by adding notes to keep crucial job
              information at your fingertips.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Calendar View Icon'} src={organise3Icon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Calendar View</FeatureTitle>
            <Copy styleLevel={2}>
              Stay on top of important interview dates and crucial details, ensuring you're fully prepared to showcase
              your talents and land that dream job. <strong>Premium users get daily and weekly email reminders.</strong>
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Organise'} src={googleMapViewIcon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Google Map</FeatureTitle>
            <Copy styleLevel={2}>
              Explore your job locations effortlessly with Google Maps integration. Visualize all your opportunities and
              grasp the commute distances at a glance.
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
        <FeatureWrapper>
          <FeatureIcon>
            <img alt={'Organise'} src={organise1Icon} />
          </FeatureIcon>
          <FeatureBody>
            <FeatureTitle>Kanban View</FeatureTitle>
            <Copy styleLevel={2}>
              Get an instant snapshot of your progress, from applications to interviews and enticing job offers, all at
              a glance!
            </Copy>
          </FeatureBody>
        </FeatureWrapper>
      </GridWrapper>
    </Container>
  );
};
